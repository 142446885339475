import { gql } from '@apollo/client';

export const LIKE_PROJECT = gql`
  mutation projectLike($id: Float!) {
    projectLike(idProject: $id, type: LIKE) {
      id
      like
      name
    }
  }
`;

export const DISLIKE_PROJECT = gql`
  mutation projectDisLike($id: Float!) {
    projectLike(idProject: $id, type: UN_LIKE) {
      id
      like
      name
    }
  }
`;

export const BASIC_SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String) {
    login(email: $email, password: $password, provider: BASIC) {
      accessToken
      tokenType
      expiresIn
      provider
      success
      message
    }
  }
`;

export const GOOGLE_SING_IN = gql`
  mutation SignIn(
    $token: String
    $email: String!
    $cupon: String
    $gclid: String
  ) {
    login(
      token: $token
      email: $email
      provider: GOOGLE
      cupon: $cupon
      gclid: $gclid
    ) {
      accessToken
      tokenType
      expiresIn
      provider
    }
  }
`;

export const FACEBOOK_SIGN_IN = gql`
  mutation SignIn(
    $token: String
    $email: String!
    $cupon: String
    $gclid: String
  ) {
    login(
      token: $token
      email: $email
      provider: FACEBOOK
      cupon: $cupon
      gclid: $gclid
    ) {
      accessToken
      tokenType
      expiresIn
      provider
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($user: AddBasicRegister!) {
    basicRegister(user: $user) {
      name
      token
    }
  }
`;

export const FULL_REGISTER = gql`
  mutation fullRegister($user: AddFullRegister!) {
    fullRegister(user: $user) {
      id
    }
  }
`;

export const CONTACT_FORM_INFO = gql`
  mutation contactRequest($contactRequest: ContactRequest!) {
    contactRequest(contactRequest: $contactRequest) {
      message
    }
  }
`;

export const SEND_NEWSLETTER_FORM = gql`
  mutation newsLetterSubscribe($suscribe: NewsLetterRequest!) {
    newsLetterSubscribe(suscribe: $suscribe) {
      message
    }
  }
`;

export const VALIDATE_USER_EMAIL = gql`
  mutation verifyEmail($verificationData: VerifyEmailInput!) {
    verifyEmail(verificationData: $verificationData) {
      success
      message
      id
    }
  }
`;

export const CREATE_INVESTMENT = gql`
  mutation createInvestment($investmentInput: InvestmentInput!) {
    createInvestment(investmentInput: $investmentInput) {
      message
      id
      couponAmount
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerifyEmail(
    $resendVerificationEmailData: VerifyEmailResendInput!
  ) {
    resendVerifyEmail(
      resendVerificationEmailData: $resendVerificationEmailData
    ) {
      message
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation createBankAccount($bankRequest: AddBankAccount!) {
    createBankAccount(bankRequest: $bankRequest) {
      message
    }
  }
`;

export const EDIT_BANK_ACCOUNT = gql`
  mutation editBankAccount($bankRequest: EditBankAccount!) {
    editBankAccount(bankRequest: $bankRequest) {
      message
    }
  }
`;

export const MAKE_PAY = gql`
  mutation makePayment(
    $paymentDetails: PaymentRequestNew!
    $projectId: Float!
  ) {
    makePaymentNew(paymentDetails: $paymentDetails, projectId: $projectId) {
      message
      status
    }
  }
`;

export const CREATE_INVESTMENT_FLEX = gql`
  mutation flexInvestment($investment: InvestFlex!) {
    flexInvestment(investment: $investment) {
      success
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($forgotPasswordData: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordData: $forgotPasswordData) {
      id
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordData: ResetPasswordInput!) {
    resetPassword(resetPasswordData: $resetPasswordData) {
      message
      id
    }
  }
`;

export const WITHDRAW_FUNDS = gql`
  mutation withdrawFunds($withdrawFundsInput: WithdrawFundsInput!) {
    withdrawFunds(withdrawFundsInput: $withdrawFundsInput) {
      message
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($changePasswordData: ChangePasswordInput!) {
    changePassword(changePasswordData: $changePasswordData) {
      message
      id
    }
  }
`;

export const REQUEST_CONTRACT = gql`
  mutation downloadContract($investmentId: Float!) {
    downloadContract(investmentId: $investmentId) {
      message
      data
      success
    }
  }
`;

export const REQUEST_NEW_CONTRACT = gql`
  mutation downloadNewContract($projectId: Float!) {
    downloadNewContract(projectId: $projectId) {
      status
      data
    }
  }
`;

export const CREATE_REFERRER = gql`
  mutation createCoupon($newReferrer: CouponInput!) {
    createCoupon(newReferrer: $newReferrer) {
      message
      success
    }
  }
`;

export const CREATE_PROMO_COUPON = gql`
  mutation createPromoCoupon($couponArg: CouponInput!) {
    createPromoCoupon(couponArg: $couponArg) {
      success
      message
    }
  }
`;

export const IMAGE_REGISTER = gql`
  mutation imageRegister($documents: AddImageRegister!) {
    imageRegister(documents: $documents) {
      dniFront
      dniBack
      selfie
      pep
    }
  }
`;

export const UPLOAD_VOUCHER = gql`
  mutation imageComprobante($documents: AddVoucher!) {
    imageComprobante(documents: $documents) {
      success
      message
    }
  }
`;

export const VALIDATE_USER = gql`
  mutation validateUser($user: ValidateUser!) {
    validateUser(user: $user) {
      success
      message
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation editUserProfile($editUser: EditUserProfile!) {
    editUserProfile(editUser: $editUser) {
      success
      message
      id
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotifications($updateNotif: NotificationsInput!) {
    updateNotifications(updateNotif: $updateNotif) {
      success
      message
    }
  }
`;

export const DELETE_ACCOUNT_BANK = gql`
  mutation deleteBankAccount($id: Float!) {
    deleteBankAccount(id: $id) {
      message
    }
  }
`;

export const WITHDRAW_FUNDS_PROJECT = gql`
  mutation withdrawFundsProjects($id: Float!) {
    withdrawFundsProjects(id: $id) {
      message
      success
    }
  }
`;

export const CONVERT_TO_PESOS = gql`
  mutation convertToPesos($convertToPesos: ConvertToPesosInput!) {
    convertToPesos(convertToPesos: $convertToPesos) {
      message
      success
    }
  }
`;
