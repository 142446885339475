import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query getProjectsByFilters($page: Float!, $filters: [FilterArgs!]!) {
    projectsByFilters(page: $page, filters: $filters) {
      count
      hasNext
      hasPrevious
      projects {
        id
        name
        streetName
        streetNumber
        apartment
        fu
        neighborhood
        city
        province
        zipCode
        lat
        lng
        typology
        materials
        design
        photo
        currency {
          id
          name
        }
        type {
          id
          name
        }
        currentValue
        profitability
        rent_profitability
        startDate
        endDate
        percentageSold
        investedAmount
        bankAccounts {
          id
          business_name
          cuit
          bank
          account_number
          cbu
          info
          bank_account_type {
            id
            name
            currency {
              id
              name
            }
          }
        }
        mt2s
      }
    }
  }
`;

export const GET_FILTERS_PROJECT = gql`
  query getFilters {
    filterList(type: PROJECT) {
      name
      filter
      fields {
        id
        name
      }
      min
      max
    }
  }
`;

export const GET_PROJECT_HISTORY = gql`
  query projectHistory($idProject: Float!) {
    getProjectHistoryById(idProject: $idProject) {
      data {
        id
        title
        comment
        status
        video_url
        date_end
        photos {
          id
          file_url
          order
        }
      }
    }
  }
`;

export const GET_PROJECT_DETAIL = gql`
  query findProject($idProject: Float!) {
    getProjectById(idProject: $idProject) {
      id
      name
      description
      materials
      design
      streetName
      streetNumber
      apartment
      fu
      neighborhood
      city
      province
      zipCode
      lat
      lng
      typology
      materials
      design
      photo
      photos
      currency {
        id
        name
      }
      type {
        id
        name
      }
      currentValue
      profitability
      rent_profitability
      startDate
      endDate
      percentageSold
      investedAmount
      bankAccounts {
        id
        business_name
        cuit
        bank
        account_number
        cbu
        info
        bank_account_type {
          id
          name
          currency {
            id
            name
          }
        }
      }
      mt2s
    }
  }
`;

export const GET_PROFILE_INFO = gql`
  query getProfileInfo {
    getUserInfo {
      id
      lastName
      name
      email
      phone
      documentType
      documentNumber
      gender
      maritalStatus
      birthdate
      nationality
      streetName
      streetNumber
      apartment
      zipCode
      province
      city
      documentPhoto
      backDocumentPhoto
      selfiePhoto
      emailVerified
      status {
        id
        name
        description
        color
      }
    }
  }
`;

export const GET_ABOUT_INFO = gql`
  query {
    getAbout {
      name
      lastName
      role
      image
      linkedin
      order
      area
      active
    }
  }
`;

export const GET_PROJECT_PROFITS = gql`
  query getProfitabilityCalculator(
    $amount: Float!
    $projectId: Float!
    $term: Float
    $currencyId: Float!
  ) {
    profitabilityCalculator(
      amount: $amount
      projectId: $projectId
      term: $term
      currencyId: $currencyId
    ) {
      amount
      revenue
      endDate
      mt2s
    }
  }
`;

export const GET_PROVINCES = gql`
  query getComboByType {
    getComboByType(type: PROVINCE) {
      value
      label
    }
  }
`;

export const GET_LOCALIDAD = gql`
  query getComboByType($idProvince: Float) {
    getComboByType(idProvince: $idProvince, type: LOCALITY) {
      value
      label
    }
  }
`;

export const GET_CIVIL_STATUS = gql`
  query getComboByType {
    getComboByType(type: CIVIL_STATUS) {
      value
      label
    }
  }
`;

export const GET_GENDER = gql`
  query getComboByType {
    getComboByType(type: GENDER) {
      value
      label
    }
  }
`;

export const GET_DOCUMENT_TYPES = gql`
  query getComboByType {
    getComboByType(type: DOCUMENT_TYPES) {
      value
      label
    }
  }
`;

export const BANK_ACCOUNT_TYPES = gql`
  query getComboByType {
    getComboByType(type: BANK_ACCOUNT_TYPES) {
      value
      label
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotification {
    getNotifications {
      notifications {
        id
        title
        message
        isRead
        href
      }
    }
  }
`;

export const GET_MY_INVESTMENTS = gql`
  query getInvestments($filters: [FilterArgs!]!) {
    getInvestments(filters: $filters) {
      count
      investments {
        id
        project
        projectTypeName
        status
        currencyType
        amount
        projectedProfit
        projectId
        paymentStatus
        paymentMethod
        paymentCurrency
        paymentAmount
        paymentCoupon
        startDate
        paymentDate
        image
        paymentLink
        mt2s
        infoProfit {
          proyectada_actual
          proyectada_final
          final
          mensual_cobrado
          mensual
          acumulada
        }
        mt2s
      }
    }
  }
`;

export const GET_INVESTMENT_ACTIVITY = gql`
  query getInvestmentActivity($investmentId: Float!) {
    getInvestmentActivity(investmentId: $investmentId) {
      success
      message
      data {
        date
        detail
        amount
      }
    }
  }
`;

export const GET_USER_WALLET = gql`
  query getUserWallet {
    getUserWallet {
      wallet
    }
  }
`;

export const GET_USER_BALANCE = gql`
  query getBalances {
    getBalances {
      currency
      amountAvailable
      wallet {
        walletId
        amount
        type
      }
    }
  }
`;

export const GET_USER_MT2S_BALANCE = gql`
  query getMt2Balance {
    getMt2Balance {
      amount
      created_at
      updated_at
    }
  }
`;

export const GET_USER_TRANSACTIONS = gql`
  query getTransactions($page: Float!) {
    getTransactions(page: $page) {
      current_page
      last_page
      transactions {
        currency
        amount
        status
        description
        type
        created_at
        mt2s
      }
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query getAllBankAccounts {
    getAllBankAccounts {
      bankAccounts {
        id
        name
        lastName
        cuil
        bank
        canRemove
        bankAccountIdentifiers {
          bank_identifier_type
          bank_identifier
        }
        bankAccountType {
          id
          name
          currency {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_COUPONS = gql`
  query getCoupons {
    getCoupons {
      success
      message
      userRefCode {
        code
        link
      }
      registeredUsers {
        registered_id
        name
        date_created
        status
        email
      }
    }
  }
`;

export const GET_PROMO_COUPONS = gql`
  query getPromoCoupons {
    getPromoCoupons(usado: false) {
      name
      code
      amount
      values {
        usd
        ars
        inv_min_ars
        inv_min_usd
      }
      expiration
      used
    }
  }
`;

export const GET_NATIONALITIES = gql`
  query {
    getNationality {
      label
      value
      name
    }
  }
`;

export const GET_GENERATE_PASSWORD = gql`
  query {
    createPassword {
      message
      success
    }
  }
`;

export const GET_FEATURED_PROJECTS = gql`
  query findProject($idProject: Float!) {
    getProjectById(idProject: $idProject) {
      id
      investmentType {
        id
        description
      }
      estimatedInvestmentTerm
      image {
        photoHeader
      }
      percentageInvested
      address {
        province
        locality
      }
      investmentType {
        id
        description
      }
      name
      currencyType
      profitability {
        value
        text
      }
      terms {
        value
        text
      }
      state {
        name
      }
      currentValue
      slug
    }
  }
`;

export const GET_COUPON_BY_CURRENCY = gql`
  query getCouponInvestments($findCoupon: CouponInvestment!) {
    getCouponInvestment(findCoupon: $findCoupon) {
      success
      message
      data {
        amount
      }
    }
  }
`;

export const GET_CURRENT_QUOTE = gql`
  query {
    getCurrentRate {
      success
      data {
        rate
      }
    }
  }
`;

export const GET_REFERRED = gql`
  query {
    getReferred {
      data {
        codigo
        enlace
        referidos_registrados
        referidos_inversores
      }
    }
  }
`;

export const GET_INVESTED = gql`
  query {
    getInvested {
      data {
        tipos {
          nombre
          moneda
          capital_invertido
          inversiones
          ganancia_acumulada
          proyectado_acumulado
        }
        monedas {
          nombre
          total
          total_acumulado
          total_proyectado
        }
      }
      success
    }
  }
`;

export const GET_AMOUNT_AVAILABLE = gql`
  query {
    getAmountAvailable {
      data {
        wallet {
          walletId
          amount
          type
        }
      }
      success
    }
  }
`;

export const GET_COUPONS_USED = gql`
  query getPromoCoupons {
    getPromoCoupons(usado: false) {
      name
      code
      type
      percentage
      amount
      type
      percentage
      values {
        usd
        ars
        inv_min_ars
        inv_min_usd
      }
      expiration
      used
    }
  }
`;

export const GET_QUOTATIONS = gql`
  query getCurrentQuotation {
    getCurrentQuotation {
      data {
        id
        currencyId
        sell
        buy
      }
    }
  }
`;

export const GET_PLAZOS = gql`
  query getDeadlines {
    getDeadlines {
      success
      message
      data {
        rentabilidad
        meses
      }
      minimo {
        ars
        usd
      }
    }
  }
`;

export const GET_BANNERS = gql`
  query {
    getBanners {
      success
      message
      data {
        id
        title
        description
        buttonText
        buttonColor
        backgroundImage
        backgroundImageMobile
        buttonUrl
        positionImage
        showButton
        image
        imageText
        colorText
        colorTitle
        buttonHoverColor
        buttonTextColor
      }
    }
  }
`;

export const GET_GRAPHIC = gql`
  query {
    getGraphicsData {
      data {
        mes
        anio
        total
      }
    }
  }
`;

export const GET_FLEX_PROJECTS = gql`
  query getProjectsByFilters($page: Float!, $filters: [FilterArgs!]!) {
    projectsByFilters(page: $page, filters: $filters) {
      count
      hasNext
      hasPrevious
      projects {
        id
        anualProfit
      }
    }
  }
`;

export const GET_POST = gql`
  query getPost($page: Float!) {
    getPost(page: $page) {
      success
      count
      featured {
        id
        slug
        status
        type
        link
        title
        status
        content
        excerpt
        cover
        yoast_head_json {
          article_modified_time
          article_published_time
          author
          canonical
          description
          og_locale
          og_site_name
          og_title
          og_type
          og_url
          title
          twitter_card
        }
      }
      data {
        id
        slug
        status
        type
        link
        title
        status
        content
        excerpt
        cover
        yoast_head_json {
          article_modified_time
          article_published_time
          author
          canonical
          description
          og_locale
          og_site_name
          og_title
          og_type
          og_url
          title
          twitter_card
        }
      }
    }
  }
`;

export const GET_POST_BY_ID = gql`
  query getPostById($id: Float!) {
    getPostById(id: $id) {
      title
      content
      cover
      yoast_head_json {
        article_modified_time
        article_published_time
        author
        canonical
        description
        og_locale
        og_site_name
        og_title
        og_type
        og_url
        title
        twitter_card
      }
    }
  }
`;

export const GET_FAQS = gql`
  query {
    getFaqs {
      success
      message
      categories {
        id
        name
        slug
        meta_title
        meta_description
        icon
      }
      questions {
        id
        question_category_id
        slug
        question
        answer
        meta_title
        meta_description
      }
    }
  }
`;
